import { Typography } from "~~/components/ds/Typography";

import { IconButton } from "~~/components/ds/IconButton";

import { Snackbar, SnackbarContent } from "@material-ui/core";
import { amber, green, indigo, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import React from "react";

import WarningIcon from "@material-ui/icons/Warning";
import { MdOpenInNew as OpenInNewIcon } from "react-icons/md";

const variantIcon = {
  success: CheckCircleIcon,
  alert: WarningIcon,
  error: ErrorIcon,
  notice: InfoIcon,
};

const getStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  notice: {
    backgroundColor: indigo[600],
  },
  alert: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {},
}));

const WelcomeAlert = ({
  onClose = () => {},
  message = {
    type: "notice",
    title: "",
    text: "",
    helpLink: undefined,
    helpLinkText: "More Help",
  },
  open = true,
  duration = 10000, // use `null` to keep open indefinitely
  hideCloseIcon = false,
  subtext = "",
  icon: OverrideIcon,
  anchorOrigin = { vertical: "top", horizontal: "right" },
}) => {
  const classes = getStyles();
  const Icon = OverrideIcon || variantIcon[message.type];

  return (
    <Snackbar
      // @ts-ignore
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={(_, reason) => {
        if (
          reason === "clickaway" &&
          hideCloseIcon &&
          typeof duration !== "number"
        ) {
          // don't close the alert bar when we click elsewhere on page
          return;
        }

        onClose();
      }}
      autoHideDuration={duration}
    >
      <SnackbarContent
        className={classes[message.type]}
        message={
          <div
            style={{ color: "white", display: "flex", alignItems: "center" }}
          >
            <Icon
              className={clsx(classes.icon, classes.iconVariant)}
              color="inherit"
              size={20}
            />
            <div>
              {message.title && (
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "1rem",
                    maxWidth: 300,
                    marginBottom: "0.25rem",
                  }}
                >
                  {message.title}
                </Typography>
              )}
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  maxWidth: 300,
                  lineHeight: "1.05rem",
                }}
              >
                {message.text}
              </Typography>
              {subtext && (
                <Typography style={{ fontSize: "0.75rem", marginTop: 2 }}>
                  {subtext}
                </Typography>
              )}
              {message.helpLink && (
                <a
                  href={message.helpLink}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-1 flex items-center font-semibold underline opacity-90 hover:opacity-100"
                >
                  {message.helpLinkText || "More Help"}
                  <OpenInNewIcon className="ml-1" />
                </a>
              )}
            </div>
          </div>
        }
        action={
          !hideCloseIcon && (
            <IconButton
              key="close"
              aria-label="close alert"
              style={{ color: "white" }}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          )
        }
      />
    </Snackbar>
  );
};

export default WelcomeAlert;
