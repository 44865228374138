import update from "immutability-helper";
import PropTypes from "prop-types";
import React from "react";
import WelcomeAlert from "~~/components/common/WelcomeAlert";

// FIXME: running into this issue https://github.com/mui-org/material-ui/issues/18166
class FlashMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: props.messages, anchorOrigin: props.anchorOrigin };

    window.flash_messages = this;
  }

  addMessage(message) {
    const oldMessages = this.state.messages; // eslint-disable-line react/destructuring-assignment
    const newMessages = update(oldMessages, { $push: [message] });
    this.setState({ messages: newMessages });
  }

  flashAlert(text) {
    return this.addMessage({
      type: "alert",
      id: Date.now(),
      text,
    });
  }

  flashError(error, helpLink) {
    let message;

    if (error instanceof Error) {
      message = error.message;
    } else if (typeof error === "object") {
      message = error.msg;
      if (error.info) {
        message += `: ${error.info}`;
      }
    } else if (typeof error === "string") {
      // *Probably* a string, but don't care too much if it's something else
      message = error;
    }

    if (!message) {
      return undefined;
    }

    return this.addMessage({
      type: "error",
      id: Date.now(),
      text: message,
      helpLink,
    });
  }

  flashSuccess(text, messageExtras = {}) {
    return this.addMessage({
      type: "success",
      id: Date.now(),
      text,
      ...messageExtras,
    });
  }

  flashNotice(text) {
    return this.addMessage({
      type: "notice",
      id: Date.now(),
      text,
    });
  }

  removeMessage(message) {
    const { messages } = this.state;
    const newMessages = messages.filter((m) => m !== message);
    this.setState({ messages: newMessages });
  }

  render() {
    const alerts = this.state.messages.filter((message) => message.text);
    const alert = alerts.length > 0 ? alerts[alerts.length - 1] : undefined;
    // we can only show one alert at time, otherwise they over lap. Only show the latest one.
    // https://material.io/components/snackbars#behavior
    if (!alert) {
      return null;
    }

    const optionalProps = {
      // each can be undefined, wherein we'll rely on defaultProps
      duration: alert?.duration,
      hideCloseIcon: alert?.hideCloseIcon,
      icon: alert?.icon,
      subtext: alert?.subtext,
      anchorOrigin: alert?.anchorOrigin ?? this.props.anchorOrigin,
    };

    return (
      <WelcomeAlert
        key={alert.id}
        message={alert}
        onClose={() => this.removeMessage(alert)}
        {...optionalProps}
      />
    );
  }
}

FlashMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

export default FlashMessages;
